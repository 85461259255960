import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PageNav from '../components/homeNav/PageNav'
import axios from 'axios'
import useAuthState from '../stores/auth'
import AddressForm from './checkout/AddressForm'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    height: '100vh',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    boxShadow: '0px 10px 20px #a3d4cb',
    borderRadius: '10px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0.5),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    fontFamily: 'Oswald',
    '& .MuiStepIcon-active': { color: '#1d9682' },
    '& .MuiStepIcon-completed': { color: 'lightgreen' },
    '& .Mui-disabled .MuiStepIcon-root': { color: 'gray' },
  },
  customStepLabel: {
    fontFamily: 'Oswald',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    maxWidth: '150px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '48px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '20px',
  },
  button: {
    margin: 'auto',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#1d968254',
    },
  },
}))

const CheckoutNavBtn = styled.a`
  background: #1d9682;
  padding: 10px 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    background: #1a7f70;
  }
`
const CheckoutNavBtn2 = styled.a`
  background: ${props => (props.disabled ? '#cccccc' : '#1d9682')};
  padding: 10px 20px;
  color: ${props => (props.disabled ? '#666666' : 'white')};
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background: ${props => (props.disabled ? '#cccccc' : '#1a7f70')};
  }

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

const PrescriptionImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 20px 0;
`

export default function PrescriptionCheckout() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [prescription, setPrescription] = useState(null)
  const [address, setAddress] = useState({ county: '', street: '' })

  const location = useLocation()
  const [isPrescriptionPicked, setIsPrescriptionPicked] = useState(false)
  const [selectedPrescription, setSelectedPrescription] = useState(null)
  const [prescriptionUrl, setPrescriptionUrl] = useState('')

  const { productId } = location.state || {}

  useEffect(() => {
    if (productId) {
      const isPrescriptionPicked =
        localStorage.getItem(`isPrescriptionPicked_${productId}`) === 'true'
      const storedFile = localStorage.getItem(`prescriptionFile_${productId}`)
      const storedUrl = localStorage.getItem(`prescriptionUrl_${productId}`)

      if (isPrescriptionPicked && storedFile && storedUrl) {
        setIsPrescriptionPicked(true)
        setSelectedPrescription(JSON.parse(storedFile))
        setPrescriptionUrl(storedUrl)
      }
    }
  }, [productId])

  const { user } = useAuthState()

  const steps = ['Review Prescription', 'Add Comment', 'Confirm and Checkout']

  useEffect(() => {
    const fetchLatestDocument = async () => {
      if (user?.id) {
        try {
          setIsLoading(true)
          const response = await axios.get(
            `${process.env.GATSBY_API_URL}/uploads/${user.id}/latest`,
          )
          setPrescription(response.data)
        } catch (error) {
          setError('Error fetching latest prescription data.')
        } finally {
          setIsLoading(false)
        }
      } else {
        setError('User ID is missing.')
        setIsLoading(false)
      }
    }

    fetchLatestDocument()
  }, [user?.id])

  const isNextDisabled = () => {
    // console.log('Checking if Next button should be disabled...');
    // console.log('Current Step:', activeStep);
    if (activeStep === 0) {
      // console.log('Address:', address);
      return !address.county || !address.street
    } else if (activeStep === 1) {
      // console.log('Comment:', comment);
      return !comment
    }
    return false
  }

  const handleNext = () => {
    setError('')
    if (activeStep === 1 && !comment) {
      setError('Comment is required')
    } else if (activeStep === 0 && (!address.county || !address.street)) {
      setError('Address is required')
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleCommentChange = event => {
    setComment(event.target.value)
  }

  const sendOrder = async () => {
    if (!user._id) {
      setError('User is not logged in')
      return
    }

    try {
      setIsLoading(true)

      // console.log('Sending order with the following details:')
      // console.log('Prescription ID:', prescription?._id)
      // console.log('Comment:', comment)
      // console.log('User ID:', user._id)
      // console.log('Address:', address)

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/prescriptions/checkout`,
        {
          prescriptionId: prescription?._id,
          comment: comment,
          userId: user._id,
          address: address,
          productId: productId,
        },
      )

      // console.log('Checkout response:', response.data)

      setSuccess('Checkout successful!')
      setComment('')
      setAddress({ county: '', street: '' })
      setActiveStep(steps.length)
    } catch (error) {
      console.error('Error during checkout:', error)
      const message = error.response?.data?.message || 'An error occurred'
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }

  function getStepContent(step) {
    if (isLoading)
      return <Typography variant="body1">Loading prescription...</Typography>
    if (error)
      return (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )

    switch (step) {
      case 0:
        return (
          <div>
            <Typography variant="h6">Review Prescription</Typography>
            <div>
              <strong>Prescription:</strong>
              {prescription ? (
                <>
                  <p>{prescription.filename}</p>
                  <PrescriptionImage
                    src={prescription.url}
                    alt="Prescription"
                  />
                  <AddressForm address={address} setAddress={setAddress} />{' '}
                </>
              ) : (
                <Typography variant="body1">
                  No prescription data available
                </Typography>
              )}
            </div>
          </div>
        )
      case 1:
        return (
          <div>
            <Typography variant="h6">Add a Comment</Typography>
            <textarea
              value={comment}
              onChange={handleCommentChange}
              placeholder="Add a comment"
              style={{ width: '100%', height: '100px' }}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
        )
      case 2:
        return (
          <div>
            <Typography variant="h6">Confirm and Checkout</Typography>
            <Typography variant="body1">
              Prescription: {prescription ? prescription.filename : 'N/A'}
            </Typography>
            <Typography variant="body1">Comment: {comment}</Typography>
            <Typography variant="body1">
              Address: {address.county}, {address.street}
            </Typography>
          </div>
        )
      default:
        throw new Error('Unknown step')
    }
  }

  return (
    <React.Fragment>
      <PageNav />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            style={{ fontFamily: 'oswald', color: '#1d9682' }}
          >
            Prescription Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel classes={{ label: classes.customStepLabel }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <div>
              {error ? (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontFamily: 'oswald' }}
                  >
                    Error processing checkout.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ fontFamily: 'oswald' }}
                  >
                    {error}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontFamily: 'oswald' }}
                  >
                    {success}
                  </Typography>
                  <CheckoutNavBtn onClick={() => navigate(-1)}>
                    Back to product
                  </CheckoutNavBtn>
                </>
              )}
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <CheckoutNavBtn onClick={sendOrder} disabled={isLoading}>
                    Confirm Checkout
                  </CheckoutNavBtn>
                ) : (
                  <CheckoutNavBtn2
                    onClick={handleNext}
                    disabled={isNextDisabled()}
                  >
                    Next
                  </CheckoutNavBtn2>
                )}
              </div>
            </div>
          )}
        </Paper>
      </main>
    </React.Fragment>
  )
}
