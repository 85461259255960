import React from 'react';
import PrescriptionCheckout from '../components/prescriptionCheckout';
import Seo from '../components/seo'


const PrescriptionCheckoutPage = () => (
  <div>
    <Seo
          title="Checkout for Your Prescription at Afyabook | Secure and Convenient"
          description="Complete your purchase with Afyabook. Securely review and finalize your prescription order, choose delivery options, and get your medication delivered to you."
          img="https://www.afyabook.com/static/upload_hero-fea6556f484fc3911d5280c94352cd4b.svg"
          keywords="prescription checkout, online pharmacy checkout, secure prescription purchase"
          siteUrl="https://www.afyabook.com/prescription-checkout"
          canonical="https://www.afyabook.com/prescription-checkout"
        ></Seo>
    <PrescriptionCheckout />
  </div>
);

export default PrescriptionCheckoutPage;
